<template>
  <v-autocomplete
    v-bind="props"
    v-model="timezone"
    :items="timezones"
    :item-value="valueKey"
    :label="label"
    item-title="label"
  />
</template>

<script lang="ts">
import { VAutocomplete } from "vuetify/components";
import timezones from "timezones-list";
import { omit } from "lodash";

export default defineComponent({
  name: "TimezoneSelect",
  extends: VAutocomplete,
  props: {
    modelValue: {},
    default: { default: String },
    returnUtc: { type: Boolean, default: false },
    returnName: { type: Boolean, default: false },
    label: { type: String, default: "Select Timezone" },
  },
  data: () => ({
    timezones,
  }),
  computed: {
    timezone: {
      set(value: any) {
        this.$emit("update:modelValue", value);
      },
      get() {
        return this.modelValue;
      },
    },
    valueKey() {
      if (this.returnUtc) return "utc";
      if (this.returnName) return "name";
      return "tzCode";
    },
    props() {
      return omit(this.$props, [
        "modelValue",
        "default",
        "returnUtc",
        "returnName",
      ]);
    },
  },
  mounted() {
    if (this.default) {
      const c = this.timezones.find((c) => c[this.valueKey] == this.default);
      if (c) {
        this.timezone = this.returnObject ? c : c[this.valueKey];
      }
    }
  },
});
</script>
